#TabElearning{
  background-color: #F4F4F4;
  border-bottom: 2px solid #dee2e6;
  border-top: 2px solid #dee2e6;
  .nav-link{
    i{
      font-size: 20px;
    }
    &.active{
      border: 1px solid white;
      i{
        color: $purple;
      }
    }
     font-weight: bold;
     border: none;
     border-top-left-radius: 0;
     border-top-right-radius: 0;
     padding: 1rem 3rem;
     color: black;
  }
  .nav-item{
    margin-bottom: 0;
  }
}
.body-header{
  background-color: white;
  -webkit-box-shadow: 2px 8px 18px 2px #D3D3D3;
  box-shadow: 2px 8px 18px 2px #D3D3D3;
}

.placeholder-sort{
  background-color: #9e9b9b;
  width: 100%;
  height: 20px;
}
#ui-doc{
  .list-docs{
    li.ui-state-disabled{
      height: 20px;
    }
  }
  .fa-folder{
    color: $purple;
  }
}

.mce-item-table{
  margin: 0 auto;
  width: 96%!important;
  td{
    padding: 10px;
  }
}