#top-details-product{
  .price{
    font-size: 25px;
    color: #e9cdbc!important;
  }
  .cream{
    background-color: #e9cdbc!important;
  }
}
#block-details-content-product{
  background-color: #f6eae6;
  .text-color-brown{
    color:#b38a80;
  }
  .left-side{
    .text{
      font-family: Seasons;
      font-size: 33px;
      display:block;
    }
  }
  .right-side{
    font-size: 18px;
    .season{
      font-family: Seasons;
    }
    .ul-bars{
      padding-left: 25px;
      list-style: none;
      margin-bottom: 8px;
      &>li{
        position:relative;
        margin-bottom: 10px;
        &:before{
          content:'';
          width: 18px;
          height: 3px;
          display:block;
          background-color: #b38a80;
          position:absolute;
          top: 11px;
          left: -25px;
        }
      }
    }
  }
}