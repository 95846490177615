body .notransform {
  transform: none !important;
  display: flex !important;
  width: 100%!important;
  @media screen and (min-width: 769px) {
    .line-video{
      width: 33.333%!important;
    }
  }
}
@media screen and (min-width: 769px) {
  .line-video{
    width: 450px;
  }
}