.resa-alert{

  position: relative;
  padding: 0.75rem 1.25rem;
  text-align: center;
  font-weight: bold;

  &.resa-success{
    background-color:darkgreen;
    color:white;
  }
  &.resa-error{
    background-color:$red;
    color:white;
  }
}
#link-formules{
  color:black;
  margin:0 auto;
  display: block;
}
.modal-login,.modal-contact{
  color:white;
  .modal-header{
    background-image: none;
  }
  a{
    color: white;
  }
}
.modal-header{
  padding: 2.8rem 1.8rem;
  font-size: 32px;
  font-weight: bolder;
  color: white;
  display: block;
  border-radius: 0!important;
  text-align: center;
}
.modal-header-info{
   @extend .modal-header;
  background-color: #a66d5b;
}

#wrong-identifiant{
  display: none;
}

.modalcustom {
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  max-width: 600px;
  box-sizing: border-box;
  width: 90%;
  background: #fff;
  padding: 30px 30px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 10px #000;
  -moz-box-shadow: 0 0 10px #000;
  -o-box-shadow: 0 0 10px #000;
  -ms-box-shadow: 0 0 10px #000;
  box-shadow: 0 0 10px #000;
  text-align: left;
  .content{
    color: #8996a2;
    font-size: 1.6rem;
  }
  &.template-popin-account{
    padding: 0!important;
    border-radius: 0px;
    header{
      padding: 30px;
      background-color: #8273f6;
      color:white;
    }
    .content{
      padding: 20px 30px;
      .but-submit{
        margin-top: 40px;
        padding: 0px 20px 0px 20px;
        height: 35px;
        font-size: 15px;
        width: 50%;
        margin: 0 auto;
      }
    }
  }
  &#popin-subscription{
    .content{

    }
  }
  &#popin-menu{
    a{
      text-decoration: none;
      height: 35px;
      padding-top:5px;
      padding-bottom:5px;
      margin-bottom: 10px;
      font-size: 16px;
    }
    .menu-content{
      display:none;
      &#reactivte-sub{
        h4{
          margin-bottom: 32px;
          color: black;
          font-size: 18px;
        }
        .line-offer{
          &+.line-offer{
            border-top: 1px solid #e3e4e4;
          }

          padding-bottom: 20px;
          padding-top: 20px;

          .title-offer{
            color:black;
          }
          .grab{
            font-size: 14px;
          }
          button{
            height: 40px;
            padding-left: 15px;
            padding-right: 15px;
            font-size: 16px;
          }
        }

      }
    }
  }

  &#popin-options{
    img{
      object-fit: cover;
    }
    button{
      height: 40px;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 16px;
    }
    li{
      margin-bottom: 5px;
    }
  }
  &#popin-add-new-Subchild{
    h3{
      color:black;
    }
    input[type="submit"]{
      width: 60%;
      height: 40px;
      font-size: 20px;
    }
  }
  #block-offer,#block-display-offer{
    button{
      width: 96px;
      height: 39px;
      font-size: 12px!important;
      padding-left: 10px;
      padding-right: 10px;
    }
    .line-offer{
      margin-bottom:15px;
    }
    .choice-offer{
      div{
        width:calc(50% - 15px);
        background-color: #f7f7f7;
        padding: 10px;
        h4{
          margin-bottom: 10px;
        }
      }
    }
    .list-offers{
      display:none;
      .line-offer{
        display:block;
      }
      &.display-visio{
        display:block;
        .line-offer.offer-visio{
          display:flex;
        }
        .line-offer:not(.offer-visio){
          display:none;
        }
      }
      &.display-essentielle{
        display:block;
        .line-offer.offer-visio{
          display:none;
        }
        .line-offer:not(.offer-visio){
          display:flex;
        }
      }
    }
  }
}

.modalcustom a.close-modalcustom {
  position: absolute;
  top: -12.5px;
  right: -12.5px;
  display: block;
  width: 30px;
  height: 30px;
  text-indent: -9999px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAA3hJREFUaAXlm8+K00Acx7MiCIJH/yw+gA9g25O49SL4AO3Bp1jw5NvktC+wF88qevK4BU97EmzxUBCEolK/n5gp3W6TTJPfpNPNF37MNsl85/vN/DaTmU6PknC4K+pniqeKJ3k8UnkvDxXJzzy+q/yaxxeVHxW/FNHjgRSeKt4rFoplzaAuHHDBGR2eS9G54reirsmienDCTRt7xwsp+KAoEmt9nLaGitZxrBbPFNaGfPloGw2t4JVamSt8xYW6Dg1oCYo3Yv+rCGViV160oMkcd8SYKnYV1Nb1aEOjCe6L5ZOiLfF120EjWhuBu3YIZt1NQmujnk5F4MgOpURzLfAwOBSTmzp3fpDxuI/pabxpqOoz2r2HLAb0GMbZKlNV5/Hg9XJypguryA7lPF5KMdTZQzHjqxNPhWhzIuAruOl1eNqKEx1tSh5rfbxdw7mOxCq4qS68ZTjKS1YVvilu559vWvFHhh4rZrdyZ69Vmpgdj8fJbDZLJpNJ0uv1cnr/gjrUhQMuI+ANjyuwftQ0bbL6Erp0mM/ny8Fg4M3LtdRxgMtKl3jwmIHVxYXChFy94/Rmpa/pTbNUhstKV+4Rr8lLQ9KlUvJKLyG8yvQ2s9SBy1Jb7jV5a0yapfF6apaZLjLLcWtd4sNrmJUMHyM+1xibTjH82Zh01TNlhsrOhdKTe00uAzZQmN6+KW+sDa/JD2PSVQ873m29yf+1Q9VDzfEYlHi1G5LKBBWZbtEsHbFwb1oYDwr1ZiF/2bnCSg1OBE/pfr9/bWx26UxJL3ONPISOLKUvQza0LZUxSKyjpdTGa/vDEr25rddbMM0Q3O6Lx3rqFvU+x6UrRKQY7tyrZecmD9FODy8uLizTmilwNj0kraNcAJhOp5aGVwsAGD5VmJBrWWbJSgWT9zrzWepQF47RaGSiKfeGx6Szi3gzmX/HHbihwBser4B9UJYpFBNX4R6vTn3VQnez0SymnrHQMsRYGTr1dSk34ljRqS/EMd2pLQ8YBp3a1PLfcqCpo8gtHkZFHKkTX6fs3MY0blKnth66rKCnU0VRGu37ONrQaA4eZDFtWAu2fXj9zjFkxTBOo8F7t926gTp/83Kyzzcy2kZD6xiqxTYnHLRFm3vHiRSwNSjkz3hoIzo8lCKWUlg/YtGs7tObunDAZfpDLbfEI15zsEIY3U/x/gHHc/G1zltnAgAAAABJRU5ErkJggg==')
}

.modalcustom-spinner {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 12px 16px;
  border-radius: 5px;
  background-color: #111;
  height: 20px
}

.modalcustom-spinner > div {
  border-radius: 100px;
  background-color: #fff;
  height: 20px;
  width: 2px;
  margin: 0 1px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out
}

.modalcustom-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s
}

.modalcustom-spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s
}

.modalcustom-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s
}

.menu-mobile{
  border-radius: 0px;
  background-color: #b48a81;
  color:white;
  width: 0;
  overflow: hidden;
  visibility: visible;
  -webkit-transition: all 0.45s ease 0.25s;
  -o-transition: all 0.45s ease 0.25s;
  transition: all 0.45s ease 0.25s;
  z-index: 10;
  color: white;
  &.open{
    width: 100%;
    -webkit-transition: all 0.35s ease 0s;
    -o-transition: all 0.35s ease 0s;
    transition: all 0.35s ease 0s;
    pointer-events: all!important;
    opacity: 1;
    visibility: inherit;
  }
  .mobile-container{
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    -webkit-transition: opacity 0.35s ease 0s;
    -o-transition: opacity 0.35s ease 0s;
    transition: opacity 0.35s ease 0s;
    z-index: 5;
    .mobile-items{
      padding: 0px 33px 0 33px;
      list-style: none;
      margin-bottom: 10px;
      li{
        position: relative;
        color: rgba(255, 255, 255, 0.6);
        border-bottom: 1px solid rgba(255, 255, 255, 0.11);
        cursor: pointer;
        opacity: 0.6;
        &.active{
          opacity: 1;
        }
        a{
          padding: 30px 0;
          text-decoration: none;
          color: white;
          .icone{
            font-size: 2.4rem;
            width: 1em;
            height: 1em;
          }
          .mobile-item__label{
            font-size: 1.0rem;
            margin-left: 25px;
          }
        }
      }
    }
    .menu-mobile-profile{
      padding: 19px 26px 25px 26px;
      position: relative;
      color: #ffffff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.11);
      cursor: pointer;
      .mobile-profile-thumb-wrapper{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }
      .profile-name{
        margin-left: 15px;
        font-size: 16px;
      }
      .fa-chevron-right{
        position: absolute;
        top: 50%;
        right: 30px;
        font-size: 1.4rem;
      }
    }
  }


}
.popin-style{
  border-radius: 6px;
  -webkit-box-shadow: 0 10px 25px 5px rgba(5, 16, 43, 0.24);
  box-shadow: 0 10px 25px 5px rgba(5, 16, 43, 0.24);
  .back-btn{
    .back-btn-label{
      font-size: 16px;
      i{
        margin-right: 20px;
      }
    }
  }
  .popin__close{
    position: absolute;
    top: 0px;
    right: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 70px;
    height: 70px;
    background-color: #5a4c49;
    border: none;
    cursor: pointer;
    color:white;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
    transition: background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;
    -o-transition: transform 0.45s ease 0s, background-color 0.35s ease 0s;
    transition: transform 0.45s ease 0s, background-color 0.35s ease 0s;
    transition: transform 0.45s ease 0s, background-color 0.35s ease 0s, -webkit-transform 0.45s ease 0s;


    i{
      font-size: 20px;
    }
  }
  &.open{
    .popin__close{
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      -webkit-transition: -webkit-transform 0.4s ease 0s;
      transition: -webkit-transform 0.4s ease 0s;
      -o-transition: transform 0.4s ease 0s;
      //transition: transform 0.4s ease 0s;
      transition: transform 0.4s ease 0s, -webkit-transform 0.4s ease 0s;
    }
  }
}

.menu-mobile{
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  bottom: 0;
}
.fancybox-slide--iframe .fancybox-content{

}