@font-face {
  font-family: 'Raleway-Regular';
  src: url('/build/fonts/Raleway-Regular.ttf');
}

@font-face {
  font-family: 'Raleway-Light';
  src: url('/build/fonts/Raleway-Light.ttf');
}

@font-face {
  font-family: 'Raleway-Bold';
  src: url('/build/fonts/Raleway-Bold.ttf');
}

@font-face {
  font-family: 'Seasons';
  //src: url('/build/fonts/manuscript.otf') format("opentype");
  src: url('/build/fonts/The-Seasons-Regular.otf') format("opentype");;
}

@font-face {
  font-family: 'Manuscript';
  src: url('/build/fonts/manuscript.otf') format("opentype");
  //src: url('/build/fonts/Fontspring-DEMO-theseasons-reg.otf') format("opentype");;
}

@font-face {
  font-family: 'Raleway-Thin';
  src: url('/assets/fonts/Raleway-Thin.ttf');
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url('/build/fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url('/build/fonts/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('/build/fonts/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'PlayfairDisplay-Regular';
  src: url('/assets/fonts/PlayfairDisplay-Regular.ttf');
}

@font-face {
  font-family: 'PlayfairDisplay-Italic';
  src: url('/build/fonts/PlayfairDisplay-Italic.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('/build/fonts/New/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('/build/fonts/New/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('/build/fonts/New/Montserrat-Medium.ttf');
}