.crew-page{
  .banner-page{
    background-image: url("/static/img/crew/banner2.JPG");
    background-size: cover;
    position: relative;
    background-position: center;
    form{
      background: rgba(244, 244, 244, 0.7);
      padding: 10px;
      width: 400px;
      position: absolute;
      right: 15px;
    }
  }
  .separate{
    background-color: black;
    width: 300px;
    height: 1px;
  }
  .price{
    font-size: 25px;
    color: white;
    font-weight: bold;
  }
  .dateO{
    margin-left: 15px;
    margin-right: 15px;
    display: block;
    margin-bottom: 10px;
    color: $purple;
    font-size: 32px;
  }

  .more-details{
    font-weight: bold;
    span{
      height: 2px;
      width: 20px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  #next-workshop{
    .main-title{
      color:#b48a81;
      font-weight: bold;
      font-size: 20px!important;
      font-family: Montserrat;
    }
    .date{
      color:#b48a81;
      font-size: 20px;
    }
    .second-title{
      font-family: Seasons;
    }
  }
}



.responsive{
  .date{
    color:#b48a81;
  }
  .second-title{
    color:black;
  }
}