.top-banner-page{
  .navbar-toggler{
    background-color: #b48a81;
    color:white;
  }
  &.home-banner,&.validation-banner{
    background-image: url("/static/img/home/banner.jpg");
    background-repeat: no-repeat;
  }
  &.armelle-banner{
    background-image: url("/static/img/commun/banner-3.jpg");
    background-repeat: no-repeat;
  }
  &.online-banner{
    background-image: url("/static/img/online/banner.jpg");
    background-repeat: no-repeat;
  }

  &.coaching-banner{
    background-image: url("/static/img/coaching/banner.jpg");
    background-repeat: no-repeat;
  }

  &.product-banner{
    background-image: url("/static/img/product/banner.jpg");
    background-repeat: no-repeat;
    height: auto!important;
  }

  &.crew-banner{
    background-image: url("/static/img/crew/banner.jpg");
    background-repeat: no-repeat;
  }

  &.testimony-banner{
    background-image: url("/static/img/testimony/banner.jpg");
    background-repeat: no-repeat;
    height: 250px!important;
  }

  h1{
    margin-top:170px;
    color:#f2ece9;
  }
  h2{
    font-size: 18px;
    color:#edd5c7;
  }

  #top-link{
    margin-bottom: 80px;
  }
  .dropdown{
    .dropdown-toggle{
      color:#e9cdbc;
      font-weight: bold;
    }
    .dropdown-menu{
      background-color: #b38a80;
      a{
        color:#e9cdbc;
      }

      border-color: #b38a80!important;
    }
  }
  .link-social{
    a{
      color:#e9cdbc;
      margin-right: 5px;
      font-size: 16px;
      cursor:pointer;
    }
  }
  .top-user-action{
    .link-phone{
      color:#e9cdbc;
      text-decoration: none;
      span{
        font-size: 17px;
        color:#e9cdbc!important;

      }
    }
    .popin-login{
      color:#e9cdbc;
      text-decoration: none;
    }
  }

  span{
    color:white!important;
    font-size: 1.9vw;
  }
  #block-main-nav{
    padding-left:5rem ;
  }
}
.bg-cream{
  background-color: #dbcac4;
}
.block-section{
  .link-social{
    a{
      color:white;
      align-items: center !important;
      justify-content: center !important;
      display:flex;
      height: 50px;
      width: 50px;
      border-radius: 100%;
      padding: 10px;
      font-size: 20px;
      text-decoration: none;
      &:nth-child(1){
        background-color: #5a4b49;
      }
      &:nth-child(2){
        background-color: #b48a81;
      }
      &:nth-child(3){
        background-color: #e9cdbc;
      }
      &:nth-child(4){
        background-color: #edd5c7;
      }
    }

  }
}

.bg-papper{
  background-image: url("/static/img/commun/bg-vision.png");
  background-repeat: no-repeat;
  background-size: cover;
  h2{
    color:#f6eae6;
  }
  div{
    color:#ebdfdb;
  }
}
.armelle-page{
  #presentation{
    .main-title{
      font-size: 35px!important;
      margin-bottom: 17px!important;
    }
  }
}