.main-body{
  background-color: #f6eae6 !important;
  height:100vh;
  .banner-head{
    background-color: #bda0b5;
    color:white;
     #next-session{
       span{
         background-color: #ffffff;
         color: #a2679f;
         padding: 6px 10px 6px 10px;
         margin-right: 10px;
         font-weight: bold;
       }
     }
    div:first-child{
      border-right: 1px solid white;
    }
    a{
      color:white;
    }
    i{
      font-size:20px;
    }
    .link-connect{
      color:$purple;
      text-align: center;
    }
    .dropdown-menu{
      top: 8px;
      left: -73px;
      a{
        color:black;
      }
    }

  }
}
a{
  &:hover{
    text-decoration: none;
  }
}
#mainNavbarNavDropdown{
  .nav-link{
    color: #edd5c7!important;
    font-weight: bold;
    font-size: 15px;
    &.actif{
        color: #edd5c7!important;

    }
    &:hover{
      color:white;
    }
  }
}
nav{
  padding: 0 0 10px 0 !important;
  li{
    text-align: center;
  }
  a{
    color:white;
    font-family: 'OpenSans-Light', sans-serif;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
  }
  .nav-item-contact{
    background-color: #b48a81;

    a{
      color:#f6eae6!important;
      padding-left: 19px;
      padding-right: 19px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
footer{
  background-color: #5a4c49!important;
  color:white;
  a{
    color:white;
    text-decoration: none;
    &:hover{
      color:white;
    }
    i{
      font-size: 16px;
    }
  }
  h5{
    text-transform: uppercase;
  }
}

.page-custom{
  .left-block{
    background-color: #5A4C4A;
    ul{
      li{
        text-align: center;
        padding: 5px 5px 5px 5px;
        margin-bottom:5px ;
        a,i{
          color:white;
          font-size: 25px;
        }
        a{
          opacity: 0.5;
          display:block;
          text-decoration: none;
          &.active{
            opacity: 1;
          }
        }
        span{
          margin-top: 5px;
          font-size:12px;
        }
      }
    }
  }
    .info-calendar{
      &.light{
        background-color:  lighten(#b48a81,20);
      }
      background-color:  #b48a81;
      color: white;
    }
  #navbarDropdown{
    color: black;
    i{
      margin-right: 8px;
      color: #b48a81;
      font-size: 25px;
    }
  }
  .elearning-menu{
    background-color:  darken(#5A4C4A,20);
    color:white;
    li{
      &.active{
        background-color:  darken(#5A4C4A,30);
      }
    }
    a{
      color: white;
      text-decoration: none;
    }
  }
}
.top-banner-page{
  h1{
    font-size: 55px;
  }
}
body{
  font-family: 'Montserrat';
}

.page{
  padding-top: 30px;
}

table{
  border:none;
}