
/*=============================================
=             Colours                         =
=============================================*/

$grey:            #f4f4f4;
$red:             #cc3333;
$white:           white;
$blue:            #154785;
$gold:            #c18106;
$rose:            #be7e65;
$purple: #a2679f;
$whiteb:          #f6eae6;


  /************ layout ***********************/

$background-nav:  $grey;