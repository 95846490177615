#body-paiement{
   background-color: #eceaea;
  header{
    padding-bottom: 30px;
    padding-top: 30px;
    display: table;
    white-space: nowrap;
    text-align: center;

    &:before,&:after{
        content: "";
        display: table-cell;
        width: 50%;
        background: url(data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=) repeat-x left center;
    }
    span{
      background-color:#5A4B4A;
      color:white;
      padding:5px 10px 5px 10px;
      border-radius:5px;
      font-size:16px;
      display:block;
      margin: 0 auto;
      width:240px;
      text-align:center;
      font-weight:bold;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .icon{
    text-align: center;
    i{
      display:block;
      color:#4a4a4a;
      font-size:80px;
    }
  }
  .price{
    color:#5A4B4A;
    font-size:25px;
    font-weight:bold;
  }
}
  #section-paiement{
    .p-price{
      color: black;
      font-size: 25px;
    }
  }