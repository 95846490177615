/********* Banners Home ************/

@media screen and (min-width: 769px) {
  #grab-custom{
    background-color: black;
    position: absolute;
    z-index: 1;
    left: 61px;
    top: -11px;
  }
  .flag-translate{
    &.mobile{
      display: none;
    }
    &.laptop{
      display: flex;
    }
  }
  #elearning-content{
    overflow-y : scroll;
  }
  .home-page{
    .banner-page{
      min-height: 512px!important;
    }
  }
  .alone-page{
    .banner-page{
      min-height: 512px!important;
    }
  }
  .main-title{
    margin-bottom: 40px;
  }
  .block-section{
    padding-top: 70px;
    padding-bottom: 70px;

  }
  .top-banner-page{
    &.home-banner{
      height: 859px!important;
      padding: 60px 60px 0px 60px!important;
    }
    &.armelle-banner{
    }
    &.online-banner{
    }

    &.coaching-banner{
    }

    &.product-banner{
    }

    &.crew-banner{
    }

    &.testimony-banner{
    }

    height: 859px!important;
    padding: 60px 60px 0px 60px!important;
    background-size: cover;
    #logo{
      position: absolute;
      margin: 0 auto;
      top: 30px;
      width: 230px;
      left: 42%
    }
  }
  .page-custom{
    #display-menu-mobile{
      display:none;
    }
    .left-block{
      min-height: 100vh;
      button{
        display:none;
      }
      ul{
        display:block;
      }
      .logo{
        width: 100%!important;
      }
    }
    .right-block{
      .elearning-menu{
        display:block;
      }

    }
    #top-show-program{
      display:none;
    }
    #top-default-program{
      display:block;
    }
  }
  #coaching-quantique{
    #container-coaching-quantique{
      width: 40%;
    }
  }
}

@media screen and (max-width: 768px) {

  #coaching-quantique{
    #container-coaching-quantique{
      width: 90%;
    }
  }
  .page-custom{
    #top-show-program{
      display:flex;
    }
    #top-default-program{
      display:none;
    }
    #display-menu-mobile{
      display:flex;
      background-color: #221d1c;
      color:white
    }
    .left-block{
      justify-content: space-between !important;
      padding: 10px;
      button{
        background-color: #b48a81;
        color: white;
        height: 36px;
        display:block;
      }
      ul{
        display:none;
      }
      .logo{
        width: 58%!important;
      }
    }
    .right-block{
      iframe{
        width: 100%!important;
      }
      .elearning-menu{
        display:none;
        header{
          display:none;
        }
      }

    }
  }

  .top-banner-page{
    &.home-banner{
      background-position: center center;
    }
    &.armelle-banner{
      h1{
        font-size: 1em;
      }

    }
    &.online-banner{
    }

    &.coaching-banner{
      background-position: center center;
    }

    &.product-banner{
    }

    &.crew-banner{
    }

    &.testimony-banner{
    }

    min-height: 400px;
    padding: 20px;
    background-size: cover;
  }

.modal-header {
  background-position: 0px -75px!important;
}
  .flag-translate{
    &.mobile{
    display: flex;
    }
    &.laptop{
    display: none;
    }
  }

  #grab-custom{
    background-color: black;
    z-index: 1;
    margin-bottom: 40px;
  }

  .main-title{
    margin-bottom: 35px;
  }
  .block-section{
    padding-top: 35px;
    padding-bottom: 70px;

  }
}

@media screen and (max-width: 426px) {
  .list-item{
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .home-page{
    .banner-page{
      min-height: 210px;
    }
  }
  .modal-header, .modal-header-info {
    background-position: 0 0 !important;
  }
  .crew-page{
    .banner-page{
      form{
        width: 100%;
        position: relative;
        right: 0;
      }
    }
  }
  .no-padding-xs-lr{
    padding: 0!important;
  }


}

@media screen and (min-width: 427px) {
  .list-item{
    flex-basis: 374px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 992px) {
  .armelle-page{
    #presentation{
      img{
        padding-right: 0em;
      }
    }
  }
}

@media screen and (min-width: 993px) {
  .armelle-page {
    #presentation {
      img {
        padding-right: 6.5em;
      }
    }
  }
}


@media screen and (min-width: 1166px) {
  .top-banner-page{
    #top-link{
      .js-opener-popin{
        display:none;
      }
    }
    .link-social{
      display:flex;
    }
    #block-main-nav{
      nav{
        flex-wrap: nowrap;
        justify-content: flex-start;
        #mainNavbarNavDropdown{
          display:flex;
          flex-basis: auto;
          .navbar-nav{
            flex-direction: row;
            .nav-link{
              padding-right: 0.5rem;
              padding-left: 0.5rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1165px) {
    .top-banner-page{
      #top-link{
        .js-opener-popin{
          display:block;
        }
      }
      .link-social{
        display:none;
      }
    }
}