.page-admin{
  .left-block{
    background-color:  #5A4C4A ;
    min-height: 99vh;
    ul{
      li{
        background-color:lighten(#5A4C4A,11%);
        padding: 5px 5px 5px 5px;
        margin-bottom:5px ;
        a,i{
          color:white;
        }
        a{
          text-decoration: none;
        }
      }
    }
  }
}

footer{
  background-color: #bd9fa9;
}