.btn{

  &.call-action{
    border-radius: 0!important;
    &.black{
      color: black;
      font-family: Raleway-Bold, sans-serif;
      border: 1px solid black;
      &:hover{
        color: white;
        background-color: black;
      }
    }

    &.cream{
      background-color: #e9cdbc;
      font-family: Raleway-Bold, sans-serif;
      border: 1px solid #e9cdbc;
      &:hover{
        background-color: #e9cdbc;
        //background-color: #e9cdbc;
      }
    }
    &.brown{
      color:white;
      background-color: #b48a81;
      font-family: Raleway-Bold, sans-serif;
      border: 1px solid #b48a81;
      &:hover{
        background-color: #b48a81;
        //background-color: #e9cdbc;
      }
    }


    &.purple{
      background-color: $purple;
      color: white;
      font-family: Raleway-Bold, sans-serif;
      &:hover{
        color: white;
        background-color: darken($purple,15%);
      }
    }

    &.grey{
      background-color: $grey;
      color: white;
      font-family: Roboto-Bold;
      &:hover{
        color: white;
        background-color: darken($grey,15%);
      }
    }

    &.orange{
      background-color: #F5B700;
      color: white;
      &:hover{
        color: white;
        background-color: darken(#F5B700,15%);
      }
    }
  }
}
.text-content-popover{
  .line{
    display:block;
    margin-bottom: 5px;
    i{
      color:black;
      width: 25px;
      font-size: 16px;
    }
  }
  a{
    margin: auto;
    display: block;
    color:white;
    background-color:$grey;
    margin-top:10px;
  }
}
.content-popover{
  display: none;

}
.no-padding-lr{
  padding-right: 0!important;
  padding-left: 0!important;
}

.no-margin-lr{
  margin-right: 0!important;
  margin-left: 0!important;
}

.no-border-radius{
  border-radius:0!important;
}

.roboto-bold{
  font-family: Roboto-Bold;
}

.clear{
  clear:both;
}

.overflow{
  overflow: hidden;
}

.height-auto{
  height:auto;
}

ul{
  padding: 0;
}

.bg-black{
  background-color:black;
}

.bg-grey{
  background-color:$grey;
}
.bg-brown{
  background-color: #a66d5b;
}

.title-page{
  font-family: Raleway,sans-serif;
  &.bold{
    font-family:Raleway-Bold,sans-serif;
  }
}

.page{
  .banner-page{

    background-size: cover;
    background-repeat: no-repeat;
    min-height: 392px ;
    padding: 30px;
    position: relative;
    background-position: center;
    span{
      color:#7A7A7A;
      font-size: 25px;
      font-family: PlayfairDisplay-Regular, sans-serif;
      position: absolute;
      top: 30%;
      left: 35%;
      text-align: center;
    }
  }
}

.play-video{
  position: relative;
  color: black;
  text-decoration: none;
  display:block;
  height: auto;
  img{
    opacity: 0.8;
    &:hover{
      opacity: 1;
      i{
        color:  darken(white,10);
        opacity: 1;
      }
    }
  }
  i{
    position: absolute;
    font-size: 80px;
    color:  white;
    opacity: 0.8;
  }
}
nav[aria-label="breadcrumb"]{
  background-color: transparent!important;
}
.bg-email{
  background-image: url('/static/img/commun/bg-email.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.breadcrumb{
  padding: 0!important;
  background-color: transparent!important;
  //border-bottom:1px solid #F4F4F4 ;
  //border-top:1px solid #F4F4F4 ;
  border-radius: 0;
  .breadcrumb-item{
    color:white;
    padding: 8px 11px 5px 30px;
    margin-right: 5px;
    position: relative;
    display: block;
    height: 40px;

    &::after{
      content: "\f054";
      position:absolute;
      z-index:1;
      right:-20px;
      top:8px;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      color: $purple;
    }
    &::before{
      content: "";
      left:0;
      top:0;
    }
    &:first-child{
      &::before{
        display:none;
      }
      padding: 8px 11px 5px 0;
      border-top-left-radius:0;
      border-bottom-left-radius:0;
    }
    &:last-child{
      &::after{
        display:none;
      }
      border-top-right-radius:10px;
      border-bottom-right-radius:10px;
    }
    a{
      color:white;
    }
    &.active{

    }
  }
}

.list-container{
  display: flex;
  overflow-x: scroll;
  padding: 1rem 0;
  scroll-snap-type: x mandatory;
  .list-item{
    scroll-snap-align: start;
    flex-shrink: 0;
  }
}
.main-title{
  font-family: 'Seasons';
  font-size: 46px;
  color: black;
}

.main-title-manuscript{
  font-family: 'Manuscript';
  font-size: 60px;
  color: black;
}
.second-title{
  font-family: 'Montserrat-Medium';
  font-size: 22px;
  color: black;
  font-weight: 300;
  display:flex;
  align-items: center;
  .tiret{
    height: 2px;
    width: 15px;
    background-color: #B38A80;
    margin-right: 5px;
    display:block;
  }
}

.main-text{
  font-family: 'Montserrat';
  font-size: 14px;
  color: black;
}

.more-details{
  color: #f6eae6;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'OpenSans-Regular',sans-serif;
  background-color: #b38a80;
  text-decoration: none;
  padding: 10px;
  &:hover{
    text-decoration: none;
    background-color: darken(#b38a80,10);
    color: white;
  }
  span{
    background-color: $purple;
    height: 2px;
    width: 20px;
    display: inline-block;
    vertical-align: middle;

  }
  &.brown-2{
    color:#5a4c49;
    background-color: #EDD5C5;
    border: 1px solid #EDD5C5;
    font-family: Montserrat;
    font-weight: 300;
    font-size: 13px;
  }
}

.container2 {
  margin-bottom: 3vh;
  color: white;
  align-items: center;
}

.child {
  margin-left: 0.5rem;
  height: 100%;
  padding: 1rem;
}

.btn-left,.btn-right{
  background-color:  #b48a81;
  i{
    color: white;
  }
}

.slick-no-slide .slick-track {
  width: 100% !important;
  transform: inherit !important;
}

hr{
  border: 1px solid #d3c4c0;
  margin-top: 15px;
  margin-bottom: 15px;
}
h1{
  font-family: Seasons;
}

a{
  text-decoration: none!important;
  &:hover{
    text-decoration: none!important;
  }
}