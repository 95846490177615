.online-page{
  .banner-page{
    background-image: url("/static/img/online/banner.jpg");
  }
  .more-details{
    font-weight: bold;
    span{
      height: 2px;
      width: 20px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .line-category{
    font-size: 32px;
    margin-left: 15px;
    display: block;
    margin-bottom: 10px;
    color: black;
  }
}

       