.alone-page{
  .banner-page{
    background-image: url("/static/img/alone/banner.jpg");
  }
  .more-details{
    color: $purple;
    font-weight: bold;
    span{
      background-color: $purple;
      height: 2px;
      width: 20px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

       